import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MemberPersonalNote = () => {
  const navigate = useNavigate();
  const [personalNote, setPersonalNote] = useState("");
  console.log(personalNote, "okoko");
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-lg-8 col-sm-12 mt-3">
            <h2>Personal Note</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            {/* <button onClick={handleNavigate} className="small-contained-button">
          Add Consultant
        </button> */}
          </div>
        </div>
        {/* <IconButton onClick={() => navigate(-1)}>
      <ArrowBackIcon />
    </IconButton> */}
        <div className="col-12 mb-3">
          <TextField
            fullWidth
            className="mt-3"
            id="outlined-multiline-static"
            label="Multiline"
            multiline
            onChange={(value) => setPersonalNote(value.target.value)}
            rows={4}
          />
        </div>
        <div className="col-12 text-end">
          <button className="small-contained-button">Submit</button>
        </div>
      </div>
    </>
  );
};

export default MemberPersonalNote;
