import { invokeApi } from "../../bl_libs/invokeApi";

export const eventListApi = async (slug) => {
  const requestObj = {
    path: `/api/event/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addEventApi = async (data) => {
  const requestObj = {
    path: `/api/event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editEventApi = async (data, slug) => {
  const requestObj = {
    path: `/api/event/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteEventApi = async (slug) => {
  const requestObj = {
    path: `/api/event/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const eventDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/event/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
